<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <b-row>
      <b-col>
        <b-row>
          <b-col cols="12">
            <p class="title-tags">Selecionados</p>
            <div class="tags">
              <div
                class="tag selected"
                v-for="(opt, index) of form.value"
                :key="index"
              >
                {{ opt }}
                <v-close @click="canEdit ? removeTag(index) : null"/>
              </div>
            </div>
            <p class="title-tags">Sugestões</p>
            <div class="tags">
              <div
                class="tag"
                v-for="(opt, index) of options.filter(
                  opt => !form.value.includes(opt)
                )"
                :key="index"
                @click="canEdit ? addTag(opt) : null"
              >
                {{ opt }}
              </div>
              <input
                autocomplete="off"
                v-model="fields.custom"
                type="text"
                placeholder="Adicionar personalizada"
                v-on:keyup.enter="addCustom"
                v-if="canEdit"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </FormWrapper>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import Close from '@/assets/icons/close.svg'

export default {
  components: {
    FormWrapper,
    'v-close': Close,
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.apaAvaliacaoreAnestesica,
    })
  },
  data() {
    return {
      fields: this.getDefaultFields(),
      options: [
        'Hemograma',
        'Glicemia',
        'Sódio',
        'Potássio',
        'Creatinina',
        'RX de Toráx',
        'Eletrocardiograma'
      ]
    }
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/apaAvaliacaoreAnestesica', ['handleFields', 'handleProps']),
    getDefaultFields() {
      return {
        custom: null
      }
    },
    addTag(opt) {
      const value = [...this.form.value]
      value.push(opt)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    removeTag(index) {
      const value = [...this.form.value]
      value.splice(index, 1)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    addCustom() {
      if (this.form.value.some(v => v.toLowerCase() === this.fields.custom)) {
        this.$toast.warning('Hábito já adicionado')
        return
      }
      const value = [...this.form.value]
      value.push(this.fields.custom)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
      this.fields.custom = ''
    },
    onChange() {
      // this.attendanceReview.solicitacaoDeAPA = this.form.value.length > 0
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.value = []
    }
  },
}
</script>
<style lang="scss" scoped>
.input-tags {
  margin: 0 8px 8px 0;
  height: 38px;
}

</style>
